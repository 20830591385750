<template>
    <!-- 榜单 -->
    <div class="rankBox">
        <!-- 前三名 -->
        <div class="top1_3Box">
        <div class="top2Box" v-if="rankData[1]" @click="toAppRoom(rankData[1])">
            <div class="roomHeadBox" >
                <img v-if="rankData[1].headIcon" :src="rankData[1].headIcon" alt="">
                <img v-else src="../../assets/hallBattleV2/roomNull.png" alt="">
                <img class="toukuang" src="../../assets/hallBattleV2/top2Kuang.png" />
                <span v-if="rankData[1].micState == 1" class="liveIcon"></span>
            </div>
            <ul class="roomTop3User" v-if="rankData[1].topList">
                <li v-for="item in rankData[1].topList" :key="item.uid" @click.stop="skipUserInfo(item)">
                    <img v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/hallBattleV2/userNull2.png" alt="">
                </li>
                <li v-if="!rankData[1].topList[0]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
                <li v-if="!rankData[1].topList[1]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
                <li v-if="!rankData[1].topList[2]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
            </ul>
            <p class="roomName">{{ rankData[1].name }}</p>
            <p class="roomMoney">{{ rankData[1].score | formatNumber }}</p>
        </div>
        <div v-else class="top2Box">
            <div class="roomHeadBox">
                <img src="../../assets/hallBattleV2/roomNull.png" alt="">
            </div>
        </div>
        <div class="top2Box top1Box"  @click="toAppRoom(rankData[0])">
            <div class="roomHeadBox">
                <img v-if="rankData[0].headIcon" :src="rankData[0].headIcon" alt="">
                <img v-else src="../../assets/hallBattleV2/roomNull.png" alt="">
                <img class="toukuang" src="../../assets/hallBattleV2/top1Kuang.png" />
                <span v-if="rankData[0].micState == 1" class="liveIcon"></span>
            </div>
            <ul class="roomTop3User" v-if="rankData[0].topList">
                <li v-for="item in rankData[0].topList" :key="item.uid" @click.stop="skipUserInfo(item)">
                    <img v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/hallBattleV2/userNull2.png" alt="">
                </li>
                <li v-if="!rankData[0].topList[0]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
                <li v-if="!rankData[0].topList[1]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
                <li v-if="!rankData[0].topList[2]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
            </ul>
            <p class="roomName">{{ rankData[0].name }}</p>
            <p class="roomMoney">{{ rankData[0].score | formatNumber }}</p>
        </div>
        <div v-if="rankData[2]" class="top2Box top3Box" @click="toAppRoom(rankData[2])">
            <div class="roomHeadBox">
                <img v-if="rankData[2].headIcon" :src="rankData[2].headIcon" alt="">
                <img v-else src="../../assets/hallBattleV2/roomNull.png" alt="">
                <img class="toukuang" src="../../assets/hallBattleV2/top3Kuang.png" />
                <span v-if="rankData[2].micState == 1" class="liveIcon"></span>
            </div>
            <ul class="roomTop3User" v-if="rankData[2].topList">
                <li v-for="item in rankData[2].topList" :key="item.uid" @click.stop="skipUserInfo(item)">
                    <img v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/hallBattleV2/userNull2.png" alt="">    
                </li>
                <li v-if="!rankData[2].topList[0]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
                <li v-if="!rankData[2].topList[1]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
                <li v-if="!rankData[2].topList[2]">
                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                </li>
            </ul>
            <p class="roomName">{{ rankData[2].name }}</p>
            <p class="roomMoney">{{ rankData[2].score | formatNumber }}</p>
        </div>
        <div v-else class="top2Box">
            <div class="roomHeadBox">
                <img src="../../assets/hallBattleV2/roomNull.png" alt="">
            </div>
        </div>
        </div>
        <!-- 前三名之后 --> 
        <ul class="top4AfterBox">
            <li v-for="(item,index) in rankData" :key="item.roomId" @click="toAppRoom(item)">
                <template  v-if="index > 2">
                    <section :class="index > dangerNumber[dangerIndex] ? 'dangerRoom' : ''">
                        <div class="rankCount">
                            {{ item.top | zeroFillTop }}
                        </div>
                        <div class="roomHead">
                            <img v-if="item.headIcon" :src="item.headIcon" alt="">
                            <img v-else src="../../assets/hallBattleV2/roomNull.png" alt="">
                            <span v-if="item.micState == 1" class="liveIcon"></span>
                        </div>
                        <div class="roomNameUserIcon">
                            <p class="roomName"> {{ item.name }}</p>
                            <ul class="roomTop3User" v-if="item.topList">
                                <li v-for="subItem in item.topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                                    <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                                    <img v-else src="../../assets/hallBattleV2/userNull2.png" alt="">
                                </li> 
                                <li v-if="!item.topList[0]">
                                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                                </li>
                                <li v-if="!item.topList[1]">
                                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                                </li>
                                <li v-if="!item.topList[2]">
                                    <img src="../../assets/hallBattleV2/userNull.svg" alt="">
                                </li>
                            </ul>
                        </div>
                        <p class="roomMoney">{{ item.score | formatNumber }}</p>
                    </section>
                    <div class="dangerTips" v-if="index == dangerNumber[dangerIndex]">
                        <div>Danger</div>
                    </div>
                </template>
            </li>
        </ul>
    </div>
  </template>
  
  <script>
  import { Toast } from "vant";
  export default {
    name: "hallBattle",
    props:['dangerNumber','dangerIndex','rankData'],
    data() {
      return{
        client:''
      }
    },
    filters:{
        formatNumber(num) {
            if (num > 1000000) {
                return Math.trunc((num / 1000000) * 10) / 10 + 'M';
            } else if (num >= 1000) {
                return Math.trunc((num / 1000) * 10) / 10 + 'K';
            } else {
                return num.toString ();
            }
        },
        zeroFillTop(top){
            if(top < 10){
                return '0'+top;
            }else{
                return top;
            }
        },
    },
    created() { 
        // 判断平台
        this.judgeClient()
    },
    methods: {
        // 跳转房间
        toAppRoom(item){
            if (this.client == 'iOS') {
                window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
            } else if (this.client == 'Android') {
                nativeJs["languageRoom"](JSON.stringify(item.roomId));
            }
        },
        // 跳转资料页  需要uid
        skipUserInfo(item) {
            if (this.client == 'iOS') {
                window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.uid));
            } else if (this.client == 'Android') {
                nativeJs["skipUserInfo"](JSON.stringify(item.uid));
            }
        },
        // 判断平台
        judgeClient() {
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
                this.client = 'iOS';
            } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
                this.client = 'Android';
            } else {
                this.client = 'PC';
            }
            return this.client;
        },
    }
  };
  </script>
  