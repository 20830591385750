import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5d573b5a&scoped=true&v=1.0.2"
import script from "./index.vue?vue&type=script&lang=js&v=1.0.2"
export * from "./index.vue?vue&type=script&lang=js&v=1.0.2"
import style0 from "./index.vue?vue&type=style&index=0&id=5d573b5a&scoped=true&lang=css&v=1.0.2"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d573b5a",
  null
  
)

export default component.exports